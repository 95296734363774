export default {
  route: {
    'home': 'Home',
    'mine': 'User Center',
    'search': 'eSIM for 140+ countries and regions',
    'login': 'Login',
    'logout': 'Logout',
    'user':'Account',
    'wallet':'My Wallet',
    'order':'My Orders',
    'share':'Distribution',
    'flow':'My eSIMs',
    'coupon':'My Coupons',
    'invite':'Referral Rewards',
    'faq':'FAQ',
    'device':'Available Devices',
    'guide':'eSIM Guide',
  },
  qrcode:{
    'lang':'Language',
    'title':'Order Details',
    'guide':'Instruction Guide',
    'days': 'Days',
    'empty':'Creating... Contact us if you have any questions about your activity.',
    'subtitle_1':'Method 1：Scan QR Code',
    'subtitle_2':'Method 2：Enter code information',
    'time':'For the best experience, please activate the eSIM by {value}',
    'label_1':'SM-DP+Address',
    'label_2':'Activation code',
    'label_3':'ICCID：',
    'label_4':'Order ID：',
    'install':'Quick Install'
  },
  common:{
    'general_discounts':'General Discounts',
    'data_plan_offers':'Data Plan Offers',
    'download_tips':'Please select the browser from the upper right corner to download',
    'cancel':'Cancel',
    'ok':'Confirm',
    'all':'All Data Plan',
    'hot':'Popular Area',
    'area':'Coverage',
    'area_global':'Global with TripRoaming',
    'other':'Other Data Plan',
    'slogan':'Global Connection · Anytime & Anywhere',
    'price':'Price',
    'discount':'Discount',
    'from':'From ',
    'explain':'Detail step',
    'title_1':'Specification：',
    'title_2':'Days：',
    'title_3':'Instruction',
    'title_4':'Introduction：',
    'title_5':'Instructions for use',
    'title_6':'eSIM Details',
    'buy_now':'Buy Now',
    'compatible_text1':'eSIM compatible? ',
    'compatible_text2':'Check now!',
    'tips_1':'SIM-lock-free > Please ensure your device has "No SIM restrictions" from Settings.',
    'tips_2':'eSIM Compatible > Dial *#06#. Find the "EID" info to ensure your device is eSIM compatible.',
    'pay_1':'Confirm',
    'pay_2':'Order information',
    'pay_3':'Latest use time：',
    'pay_4':'Select payment method',
    'pay_5':'Amount Due：',
    'pay_6':'Confirm Pay',
    'pay_7':'Order amount：',
    'pay_8':'Order No：',
    'pay_9':'Submitted successfully, please pay ASAP',
    'pay_10':'Cancel',
    'pay_11':'Complete',
    'pay_12':'Checking, please wait',
    'pay_13':'Discounts：',
    'pay_14':'Original Price：',
    'pay_15':'Discount of ：',
    'search_1':'Search key word：',
    'search_2':'Search result',
    'search_3':'No Found',
    'search_4':'Please enter key word',
    'check_order':'Check order',
    'pay_again':'Pay Again',
    'back_home':'Back Home',
    'result_success':'Payment Successful',
    'result_fail':'Payment Failed',
    'p_result_tips':'Order number: {text} eSIM configuration takes 1-3 minutes, thank you for your patience.',
    'c_result_tips':'Order number: {text} Wallet top-up success, Please wait for 1-3 minutes to create the order, thank you for your support.',
  },
  reviews:{
    'title':'TraveleSIM is relied on by over 1,000,000+ users worldwide',
    'add_up':'From 6,000+',
    'reviews':'Reviews'
  },
  wallet:{
    'balance':'Available Balance',
    'details':'Details',
    'card_expenses':'Expenses',
    'card_receipts':'Receipts',
    'card_rules':'Top-up rules',
    'subtitle':'Top-up to My Wallet',
    'give':'+Gift',
    'wallet_pay':'Pay with My Wallet',
    'top_up':'Top Up',
    'available_balance':'Available Balance',
    'insufficient_funds':'Insufficient Funds',
    'transaction_details':'Transaction Details',
    'all':'All',
    'expenses':'Expenses',
    'receipts':'Receipts',
    'consent':'I agree to',
    'services_agreement':'TraveleSIM Payment Services Agreement',
    'tips':'Please read and agree to the relevant agreements and policies'
  },
  esimchange:{
    'title':'How do I switch between eSIMs?',
    'ios_1':'<span>1.</span> <span>Go to <b>Settings</b></span>',
    'ios_2':'<span>2.</span> <span>Go to <b>Tap Cellular</b></span>',
    'ios_3':'<span>3.</span> <span>Select the <b>eSIM</b> you want to use</span>',
    'ios_4':'<span>4.</span> <b>Turn On This Line & Data Roaming</b>',
    'android_1':'<span>1.</span> <b>Settings - Mobile network</b>',
    'android_2':'<span>2.</span> <span>Go to <b>SIM management</b></span>',
    'android_3':'<span>3.</span> <span>Switch the <b>Default mobile data</b></span>',
    'android_4':'<span>4.</span> <span>Make sure <b>Data roaming</b> is on</span>',
  },
  footer:{
    'name':'Triproaming International Holdings Ltd',
    'hk_name':'Hong Kong Headquarters',
    'hk_addess':'Unit 1103, 11/F., Orient International Tower, 1018 Tai Nan West Street, Cheung Sha Wan, Kowloon, Hong Kong',
    'am_name':'Macau Branch office',
    'am_addess':"F & G, 17FL, Centro Commercial Cheng Feng, 336-342 Alameda Dr. Carlos D'Assumpcao",
    'sz_name':'Shenzhen Branch office',
    'sz_addess':'Unit 2113, Oriental Technology Building, 3 Kewei Road, NanShan, Shenzhen',
    'tw_name':'Taiwan Branch office',
    'tw_addess':'No. 35, Yongding 2nd St., Beitun Dist., Taichung City 406026, Taiwan (R.O.C.)',
    'link_title':'Link',
    'link_yyb':'Triproaming Official Website',
    'link_wyb':'TravelMate Data Mall',
    'link_esim':'TraveleSIM Data Mall',
    'contact_us':'Contact us',
    'commerce_contact':'Commerce Contact',
    'scan_app':'Scan to download APP',
    'scan_wechat':'Scan to follow the ',
    'download':'Download TraveleSIM APP',
    'rights':'All Rights Reserved.'
  },
  coupon:{
    'go':'GO',
    'use':'Go to use the coupon',
    'check':'Check the coupon',
    'tab_1':'Waiting to be used',
    'tab_2':'Aready be used',
    'tab_3':'Expired',
    'tab_4':'Available coupon',
    'tab_5':'Unvailable coupon',
    'disabled':'No more than amount',
    'expired':'Coupon is expired',
    'empty':'No coupon temporary',
    'no_available':'No available coupon',
    'tips':'Please select coupon',
    'auto':'Already slected the best coupons automatic for you',
  },
  invite:{
    'mycode':'Your Exclusive Invitation Code',
    'code':'Your Exclusive Invitation Code',
    'copy':'Copy',
    'share':'Share now',
    'records':'Invitation Record',
    'use':'eSIM Now',
    'no_record':'No record yet, to share your exclusive invitation code now~',
    'know':'I know it',
    'now':'Experience now',
    'invited':'The total quantity of friends invited',
    'coupons':'A total of coupons (pcs)',
    'rules':'Activity Rules',
    'text_1':'Share your invitation code to get the coupons.',
    'text_2':'Share Invitation Code',
    'text_3':'Recommend TraveleSIM to your friends by sharing your exclusive invitation code.',
    'text_4':'New Register Coupon',
    'text_5':'Remind your friends to register with your exclusive invitation code to get the $1 coupons. (During the Activity)',
    'text_6':'Get Your Reward',
    'text_7':'TraveleSIM will automatically issue a $1 coupon for you, once the invitee finished the registration.',
    'text_8':'Share text has been copied',
    'text_9':'The text has been copied, please share it Give it to your friends, experience eSIM together~',
  },
  login: {
    'invite': 'Sign up with the invitation code , get a coupon available',
    'reset':'Passwords do not match. Please re-enter',
    'logout_title':'Logout',
    'logout_text':'Confirm to logout?',
    'logout_ok':'Logout',
    'title_1':'Login',
    'title_2':'Register',
    'title_3':'Forgot password',
    'title_4':'Binding Account',
    'tips_0':'Enter E-mail',
    'tips_1':'Enter Phone number',
    'tips_2':'Confirm password',
    'tips_3':'Enter password',
    'tips_4':'Enter verification code',
    'tips_5':'Get code',
    'tips_6':'Account existed， ',
    'tips_7':' Login',
    'tips_8':'Enter new password',
    'tips_9':'Confirm new password',
    'tips_10':'Go back',
    'tips_11':'Please enter the invitation code (optional)',
    'tips_12':'s Resend Code',
    'tips_13':'Enter Phone Number/E-mail',
    'tips_14':'Select a Country code',
    'tips_15':'Please Enter Correct E-mail',
    'tips_16':'Please Enter Correct Phone Number',
    'tips_17':'Enter 6-16 digit letter combination password',
    'area':'Select area',
    'treaty_1':'《User Agreement》',
    'treaty_2':'《Privacy Policy》',
    'text_1':`Enter Registered account. Click"Login", that's mean you agree our `,
    'text_2':'Please verify your E-mail',
    'text_3':'Please verify your Phone Number',
    'text_4':`Enter Email, creat TraveleSIM account. Click"Register", that's mean you agree our`,
    'text':'and',
    'tab_1':'or phone',
    'tab_2':'or E-mail',
    'other':'OR'
  },
  mine:{
    'ios_title':'iOS Manual',
    'android_title':'Android Manual',
    'tab_1':'All',
    'tab_2':'Unpaid',
    'tab_3':'Non-used',
    'tab_4':'In Use',
    'tab_5':'Expired',
    'tab_6':'Time left：',
    'user_1':'Account：',
    'user_2':'Password',
    'user_3':'Set',
    'user_4':'Modify',
    'user_5':'Binding Email',
    'user_6':'Bound',
    'user_7':'Unbound',
    'user_8':'Modify',
    'user_9':'Bind',
    'user_10':'Binding Phone',
    'user_11':'Enter old password',
    'user_12':'Enter new password',
    'user_13':'Enter E-mail',
    'user_14':'Enter phone number',
    'user_15':'Share Link',
    'user_16':'Copy',
    'user_17':'Set Password',
    'user_18':'Share Code',
    'order_1':'Data plan',
    'order_2':'Order Time',
    'order_3':'Paid',
    'order_4':'Order Status',
    'order_5':'Method',
    'order_6':'Action',
    'order_7':'Order No',
    'order_8':'Buy now',
    'order_9':'Actual pay：',
    'order_10':'No Orders',
    'order_11':'Coupon',
    'flow_1':'In Use',
    'flow_2':'Non-used',
    'flow_3':'Copy already',
    'flow_4':'Copy failed',
    'flow_5':'DATA USED：',
    'flow_6':'Activation Time',
    'flow_7':'Expiration Time',
    'flow_8':'Method 1: Scan the QR Code',
    'flow_9':'Method 2: Enter Code Information',
    'flow_10':'Activation Code',
    'flow_11':'Instruction Guide',
    'flow_12':'Buy again',
    'flow_13':'Expires at {msg}',
    'flow_14':'No eSIM',
    'flow_15':'SM-DP+ Address',
    'flow_16':'Expired',
    'flow_17':'A certain delay to data usage display. <br/>Please refer to the actual usage.',
    'share_0':'Select the Data',
    'share_1':'Booking time',
    'share_2':'Order No',
    'share_3':'Please enter the order number',
    'share_4':'Status',
    'share_5':'Please select status',
    'share_6':'Paid',
    'share_7':'Refunded',
    'share_8':'Export',
    'share_9':'Reset',
    'share_10':'Search',
    'share_11':'Account',
    'share_12':'Qty',
    'share_13':'Amount',
    'share_14':'Commission',
    'share_15':'Type',
    'share_16':'Source Account',
    'share_17':'Commission rate'
  },
  http: {
    'timeout':'Server response timed out, please refresh the pag',
    'fail': 'Data acquisition failed',
    'server_fail':'Failed to connect to server',
    'err': 'Request interface failed',
    '400': 'error',
    '404': 'Request error, the resource was not found',
    '500':'Server error',
    '502':'Network error',
    '504':'Network delay',
  },
  user:{
    'top_up_title':'TraveleSIM Payment Services Agreement',
    'top_up_rules':`
    <p>
    This Payment Services Agreement (hereafter "Payment Agreement") is a contract between the users (hereafter "you" or "your") and TripRoaming International Holdings Limited (hereafter "TraveleSIM", "we", "our" or "the platform" as the context may require). You agree that any use by you of any of the Payment Services (as defined below) that we offered to you will constitute your acceptance of this Payment Agreement and shall follow with the top-up procedure. 
    </p>
    <p>
    Before using the services provided by TraveleSIM, users should carefully read this Payment Agreement, especially the provisions concerning exemption or limitation of liability. <span> If you do not agree to this Payment Agreement and, /or its modifications, please stop using the top-up services provided by TraveleSIM.</span> By continuing to use the top-up services during the payment procedure, users fully accept all the terms under this Payment Agreement.
    </p>
    <p>
    Reminder: <span>TraveleSIM strongly opposes the use of Payment Services by minors without the consent of their guardians. Minors should either entrust their guardians to operate or operate with the express consent of their guardians. Otherwise, please do not use the Payment Services.</span>
    </p>
    <p class="subtitle">
    1.Service Description
    </p>
    <p>
    1.1.Users can top up through TraveleSIM's Payment Services to purchase related products and services on TraveleSIM's Platform.
    </p>
    <p>
    1.2.Users can check the Available Balance in "<b>Account - My Wallet</b>" after top up successfully.
    </p>
    <p>
    1.3.Users can select to pay by My Wallet on the order payment page to pay with the Available Balance. Transaction details can be checked via "<b>Account - My Wallet - Expenses/Receipts</b>".
    </p>
    <p>
    1.4.If the users want to request a refund for the top-up amount, please contact our customer service within 180 days (6 months) after that top-up is paid successfully.<span> The unused portion of the top-up amount can be eligible for a refund, and 25% of the refundable amount will be deducted as the processing fee.</span> The remaining amount, with the processing fee deducted, will be  refunded within 3 business days after the request is submitted successfully.
    </p>
    <p class="subtitle">
    2.Service Rules (Important Terms for Users)
    </p>
    <p>
    2.1.Accuracy of Recharge Information
    </p>
    <ul>
      <li>
      2.1.1.You can choose the specific payment method on the top-up service interface and complete the process as instructed on the page. <span>Before making a payment, please carefully verify the specific information such as the account ID, payment method, and payment amount.</span>
      </li>
      <li>
      2.1.2.After choosing the top-up amount and paying successfully with a specific payment method, the corresponding amount will be added to your wallet (shown as the "Available Balance"). Please keep the top-up order number as a reference for future issues (if a user complains about the top-up service but lacks valid evidence, we will not provide compensation).
      </li>
      <li>
      When users input the wrong account number, operate improperly, or deviate from the top-up procedure, leading to the selection of the wrong payment method, incorrect top-up amount or accounts, the top-up errors due to personal reasons, and result in harm to the user's rights and interests—<span>no compensation for such instances.</span>
      </li>
    </ul>
    <p>
    2.2.Legality of the Top-up Process
    </p>
    <ul>
      <li>
      2.2.1.Users must abide by the rules of this Payment Agreement as well as the laws and regulations. To strictly use the top-up services provided by TraveleSIM with the regulations of the relevant regulatory authorities. If you use services provided by third-party payment institutions during the top-up procedure, you should also abide by the agreements and service rules of the institutions.
      </li>
      <li>
      2.2.2.<span>Users shall ensure and undertake that the funds, to be used for the top-up on TraveleSIM's platforms, come from your legal property and that the top-up behavior has no illegal or criminal purposes.</span> Suppose the funds you used for top-up are deemed illegal by national judicial or law enforcement authorities, leading to coercive measures against TraveleSIM's bank accounts, including freezing and deduction. In that case, you shall be responsible for compensating TraveleSIM for all losses, including but not limited to notarization fees, litigation fees, preservation fees, lawyer fees, and the full amount subject to compulsory deduction.
      </li>
      <li>
      2.2.3.Users should be aware that if you top up illegally or via the methods not specified by TraveleSIM, we are not responsible for guaranteeing the top-up procedure to finish. In this case, if the user's rights and interests are harmed, TraveleSIM will not compensate and reserves the right to freeze or cancel the user account and recover the losses suffered by the platform.
      </li>
      <li>
      2.2.4.Users are not allowed to engage in any illegal or criminal activities (such as money laundering, cashing out, etc.) through top-up payment on TraveleSIM. Users are not allowed to exploit top-up vulnerabilities for profit. Otherwise, TraveleSIM has the right to terminate services, recover illegal gains, and, in serious cases, transfer to judicial authorities under the law.
      </li>
    </ul>
    <p>
    2.3.Payment Security Criterion
    </p>
    <ul>
      <li>
      2.3.1.Users should properly safeguard personal information, including but not limited to bank account numbers, passwords, verification codes, etc. while using top-up services.
      </li>
      <li>
      2.3.2.TraveleSIM prohibits users from using or topping up other users' accounts using theft or impersonation. In case of discovery, TraveleSIM will suspend or terminate partial or all account functions and services without refund or payment of any fee.
      </li>
    </ul>
    <p class="subtitle">
    3.Changes to Agreement and Force Majeure
    </p>
    <p>
    3.1.You understand and acknowledge that TraveleSIM may update or modify this Payment Agreement from time to time by changes in national laws and regulations or changes in the platform's services, and will send you modification notices through reasonable means, including but not limited to system notifications, pop up notifications and message, etc. If you do not agree with any changes to this Payment Agreement, you should stop using the top-up services from the effective date of the changes; if you continue to use the top-up and payment services after the changes take effect, it will be deemed that you agree to abide and be bound by those changes.
    </p>
    <p>
    3.2.<span>For various situations and disputes caused by force majeure and unforeseen circumstances, TraveleSIM will notify users in advance as much as possible. You understand and agree that TraveleSIM is not responsible for them.</span> Force majeure and unforeseen circumstances include but are not limited to hacker attacks, telecommunications departments Major impacts due to technical adjustments, temporary closures due to government regulations, and virus attacks.
    </p>
    <p>
    <span>By this statement, you hereby declare that you have diligently read and fully comprehended the terms of this Payment Services Agreement, and willingly acknowledge the binding nature of all the aforementioned provisions.</span>
    </p>
    `,
    'privacy_title':'TraveleSIM Privacy Policy',
    'privacy':`
    <p>TraveleSIM complies with applicable data protection and privacy laws and regulations ("Applicable Laws"). To the maximum extent permitted by applicable laws and subject to the valid consent of the user under the requirements of applicable laws, when the user uses TraveleSIM software, services, websites or any other third-party platforms that provide TraveleSIM services, it means that users Agree and accept this privacy policy. We may update this privacy policy from time to time to reflect changes in our software or products and user opinions, or changes in applicable laws. If there are major changes in this privacy policy or the way we use the personal data of users, we will publish relevant notices in an obvious way or send notices directly to users before implement. We encourage users to read this privacy policy regularly to learn how we are collecting, using and protecting user information.</p>
    <P class="subtitle">1. Personal information collection</P>
    <P>We may collect personal data from time to time in order to provide users with our products and services. The information includes but not limited to:</P>
    <P>(a) The information provided by the user during registration, displayed on the TraveleSIM APP/ online search directory/ personal center page . This may include the user's avatar, mobile phone number, etc., as well as all other information that the user agrees to provide;</P>
    <P>(b) Identification data (such as ,mobile phone number);</P>
    <P>(c) Electronic identification data (such as IP address, cookies);</P>
    <P>(d) Information about users' use of our software, services, products or webpage interactions (including computer, client information, error reports, page browsing statistics, browser type and usage statistics);</P>
    <P>(e) Flow data (data processed for transmission of communication or billing);</P>
    <P>(f) Payment details, including recipient information and payment method;</P>
    <P>(g) Contact details, including contact name, phone number, etc.</P>
    <P>In addition, the user's online time period data may be collected indirectly in a statistical and anonymous manner. </P>
    <P>If the user is under the age of 18, the user needs to provide personal information with the consent and supervision of the parent or guardian. </P>
    <p>To implement the [Login via Third Party Platform] function, we utilize Google's OAuth product. Refer to the following documents for more information:</p>
    <p>Google APIs Terms of Service：<a href="https://developers.google.com/terms" target="_blank">https://developers.google.com/terms</a></p>
    <p>Google API Services User Data Policy：<a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank">https://developers.google.com/terms/api-services-user-data-policy</a></p>
    <P class="subtitle">2. Use of personal information</P>
    <P>Our main purpose of collecting information is to provide users with a safe, smooth and efficient experience. Subject to the effective consent of the user, we will collect and use or entrust a third-party service provider to collect and use the personal data on our behalf when we obtain permission or necessary, such as:</P>
    <P>(a)Obtain, process or transmit orders generated through our website, issue invoices to users or notify users of order status;</P>
    <P>(b)Provide users with other services (as described when we collect information);</P>
    <P>(c)Provide customers with emergency rescue and other troubleshooting services ;</P>
    <P>(d)Comparing the accuracy of information;</P>
    <P>(e)Notify users about service updates and malfunctions;</P>
    <P>(f)Resolve disputes;</P>
    <P>(g)Verify the user’s identity;</P>
    <P>(h)Improve our software, services or website;</P>
    <P>(i)Plan and execute our marketing or promotion activities, discounts and similar activities;</P>
    <P>(j)Provide certain software, functions and websites for free through advertising support;</P>
    <P>(k)Contact users and send relevant messages for faults and service issues;</P>
    <P>(l)Notification by e-mail, software or text message to let users know about the final compensation information about using TraveleSIM software, products or websites, including but not limited to infringement claims for infringement of the rights of third parties;</P>
    <P>(m)Use SMS or TraveleSIM messages to notify users of the latest information about TraveleSIM software, services or websites, and inform users related promotions.</P>
    <P class="subtitle">3. Disclosure and transfer of users' personal information</P>
    <P>Except as described below, without the valid and express permission of the user, TraveleSIM will not sell, rent, trade or transfer any personal traffic data or communication content, unless TraveleSIM under the obligation to do the above behaviors by applicable laws or jurisdictions. </P>
    <P>  TraveleSIM may disclose personal information in response to legal requirements, exercise our legal rights or defend against claims, to protect TraveleSIM’s interests, combat fraud and implement our policies or protect users’ legal rights, property or safety .</P>
    <P>  TraveleSIM, and its partners, or communication operators or companies that promote users may provide personal information, communication content or traffic data to government agencies that require such information in the appropriate judicial, law enforcement, or lawful manner. Subject to the valid and explicit consent of the user, TraveleSIM may provide the user's personal information to the supplier or TraveleSIM's affiliated company.</P>
    <P class="subtitle">4. User's personal information security and storage</P>
    <P>We will store user's personal information during the period when the user is our customer or after user no longer uses our service, but this is only necessary or in response to legal requirements. We will strictly implement applicable obligations and exceptions under relevant laws, and take appropriate organizational and technical measures to protect the personal data and traffic data provided to or collected by them. The user’s personal data and traffic data are only accessible by authorized employees or third parties, and will only be saved when necessary until the original purpose of the collection or directly related purposes is fulfilled. The personal data and traffic data are saved to comply with Except for any applicable legal or contractual obligations.</P>
    <P class="subtitle">5. View or correct data</P>
    <P>The user has the right to consult the user's personal information and correct it under relevant circumstances. </P>
    <P>Unless the law requires retention or for legitimate business purposes, we will make reasonable efforts to satisfy the request to delete personal information. </P>
    <P>In accordance with current laws, we reserve the right to charge a reasonable fee for processing any data access request.</P>
    <P class="subtitle">6. Mobile phone notification service</P>
    <P>When the user's mobile phone uses TraveleSIM, we may use the mobile phone's notification service to let the user know the information while the user doesn’t executing or using the TraveleSIM application. These services may be provided by third parties. For example, messages sent to Apple devices will use Apple's emergency notification service. </P>
    <P> The notification service may receive information about the caller, the sender, and the content to provide the service, and may use this information in accordance with the terms and conditions of the service provider and the privacy policy. </P>
    <P>(Apple is a registered trademark of Apple Inc. in the United States and other countries.)</P>
    <P class="subtitle">7. Contact Us</P>
    <P>If users have any inquiries about this privacy policy, please contact our customer service in the TraveleSIM app.</P>`,
    'treaty_title':'User Agreement',
    'treaty':`
    <p>TraveleSIM reminds you (user) to carefully read this "User Agreement" (hereinafter referred to as the "Agreement") before registering as a user to ensure that you fully understand the terms of this agreement. Unless you accept all the terms of this agreement, or you have no right to register, log in or use the services covered by this agreement. Your registration, login, use, etc. will be regarded as acceptance of this agreement, and you agree to be bound by the terms of this agreement.</p>
    <p>This agreement stipulates the rights and obligations between TraveleSIM and users regarding TraveleSIM software services (hereinafter referred to as "services"). "Users" refer to individuals who register, log in, and use this service.</p>
    <p class="subtitle">1. Account registration</p>
    <p>1.1 The user needs to register a TraveleSIM account with a mobile phone number or email address before using the service. TraveleSIM may change the method of account registration and binding according to user or product needs without prior notice to users.</p>
    <p>1.2 TraveleSIM is a mobile Internet product based on geographic location, when registering, users should authorize TraveleSIM to disclose and use their location. it means the user has agreed to do that.</p>
    <p>1.3 In view of the binding registration method of TraveleSIM account, TraveleSIM will automatically extract information such as the user's  mobile phone number and mobile device identification code when user registering.</p>
    <p>1.4 TraveleSIM needs to collect personal information that can identify the user When registering and using the service for which TraveleSIM can contact users if necessary, also to provide a better experience to user.</p>
    <p class="subtitle">2. Service content</p>
    <p>The specific content of this service is provided by TraveleSIM according to the actual situation. TraveleSIM may change the services and update them in new version.</p>
    <p class="subtitle">3. User's personal privacy information protection</p>
    <p>3.1 In the process of registering an account or using this service, the user may need to fill in or submit some necessary information, such as the identity information required by laws and regulations, regulatory documents (hereinafter referred to as "laws and regulations"). If the information submitted by the user is incomplete or does not meet the requirements of laws and regulations, the user may not be able to use the service or be restricted in the process of using the service.</p>
    <p>3.2 Personal privacy information refers to information related to the user's personal identity or personal privacy, such as the user's real name, mobile phone number, mobile device identification code, and IP address. Non-personal private information refers to the basic record information of the user's operating status and usage habits of this service, which is clearly and objectively reflected on the TraveleSIM server side, other general information outside the scope of personal private information, and the above-mentioned private information that the user agrees to disclose.</p>
    <p>3.3 TraveleSIM respects and ensures the safety of users' personal privacy information, will prevents users' personal privacy information from being leaked, damaged or lost. When the aforementioned situation occurs or TraveleSIM discovers that the aforementioned situation may occur, remedial measures will be taken in a timely manner.</p>
    <p>3.4 TraveleSIM will not disclose user's personal privacy information to any third party without the user's permission, except following   circumstances:</p>
    <p>(1) TraveleSIM provides users' personal privacy information in accordance with laws and regulations or instructions from competent authorities;</p>
    <p>(2) The leakage of any personal information caused by the user sharing the registered account with others, or the leakage of personal privacy information caused by other reasons other than TraveleSIM;</p>
    <p>(3) Users disclose their personal privacy information to third parties by themselves;</p>
    <p>(4) The user and TraveleSIM and its partner units have reached an agreement on the use and disclosure of the user's personal private information;</p>
    <p>(5) In order to prevent fraudulent use of the identity of others to register, this software will verify the mobile phone number through SMS, and the SMS fee for registration or invitation will be charged by the operator;</p>
    <p>(6) Any leakage of personal privacy information of users due to hacker attacks, computer virus intrusion and other force majeure events.</p>
    <p>3.5 The user agrees that TraveleSIM may use the user's personal information in the following situations:</p>
    <p>(1) TraveleSIM sends important notices to users in a timely manner, such as software updates and changes to the terms of this agreement;</p>
    <p>(2) TraveleSIM conducts internal audits, data analysis and research, etc. to improve TraveleSIM's products, services and communication between users;</p>
    <p>(3) In accordance with this agreement, TraveleSIM manages, reviews user information and performs processing measures;</p>
    <p>(4) Other matters stipulated by applicable laws and regulations.</p>
    <p>Except for the above situations, TraveleSIM will not apply the user's personal privacy information to any other purpose without user’s permission.</p>
    <p>3.6 TraveleSIM attaches great importance to the protection of personal privacy information of minors. TraveleSIM will rely on the personal information provided by the user to determine whether the user is a minor. Any minors under the age of 18 who register for an account or use this service should obtain the permission of their parents or their legal guardians (hereinafter referred to as "guardians") in advance. Except for disclosure in accordance with laws and regulations and instructions from competent authorities, TraveleSIM will not use or disclose personal privacy information of minors to any third party.</p>
    <p>3.7 User confirmation: Geographical location is non-personal private information, and successful registration account by the user is deemed to be a confirmation that TraveleSIM is authorized to freely extract, disclose and use the user's geographic information. The user's geographic location information will be one of the user's public information, which will be disclosed by TraveleSIM to other users.</p>
    <p>3.8 In order to improve TraveleSIM's technology and services and provide users a better service experience, TraveleSIM will collect and use or provide users' non-personal privacy information to third parties.</p>
    <p class="subtitle">4. content specification</p>
    <p>4.1 The content mentioned in this article refers to any content created, uploaded, copied, published, and disseminated during the user's use of this service, including but not limited to account avatar, name and other registration information and authentication information, as well as other user accounts or this service The content produced.</p>
    <p>4.2 Users shall not use TraveleSIM account or this service to produce, upload, copy, publish, or disseminate the following contents prohibited by laws, regulations and policies:</p>
    <p>(1) Opposing the basic principles established by the Constitution;</p>
    <p>(2) Endangering national security, leaking state secrets, subverting state power, and undermining national unity;</p>
    <p>(3) Damage to national honor and interests;</p>
    <p>(4) Inciting ethnic hatred, ethnic discrimination, or undermining ethnic unity;</p>
    <p>(5) Undermining the state's religious policies and promoting cults and feudal superstitions;</p>
    <p>(6) Spreading rumors, disturbing social order, and undermining social stability;</p>
    <p>(7) Spreading obscenity, pornography, gambling, violence, murder, terror or instigating crime;</p>
    <p>(8) Insulting or slandering others, infringing on the lawful rights and interests of others;</p>
    <p>(9) Information containing other content prohibited by laws and administrative regulations.</p>
    <p>4.3 Users are not allowed to use TraveleSIM account or this service to make, upload, copy, publish, and disseminate the following content that interferes with the normal operation of TraveleSIM and infringes the legitimate rights and interests of other users or third parties:</p>
    <p>(1) Contains any sexual or sexual suggestion;</p>
    <p>(2) Contains abusive, intimidating, or threatening content;</p>
    <p>(3) Containing harassment, spam, malicious information, or deceptive information;</p>
    <p>(4) Involving other people's privacy, personal information or data;</p>
    <p>(5) Infringement of legal rights such as reputation rights, portrait rights, intellectual property rights, and trade secrets of others;</p>
    <p>(6) Contains other information that interferes with the normal operation of this service and infringes the legal rights of other users or third parties.</p>
    <p class="subtitle">5. Rules of Use</p>
    <p>5.1 Any content transmitted or published by users in or through this service does not reflect or represent, nor should it be deemed to reflect or represent TraveleSIM’s views, positions or policies, and TraveleSIM shall not bear any responsibility for this.</p>
    <p>5.2 Users are not allowed to use TraveleSIM account or this service to do the following:</p>
    <p>(1) Submitting or publishing false information, or embezzling other people's avatars or materials, impersonating or using the names of others;</p>
    <p>(2) Forcing or inducing other users to follow, click on linked pages or share information;</p>
    <p>(3) Fictional facts, concealing the truth in order to mislead or deceive others;</p>
    <p>(4) Using technical means to establish false accounts in batches;</p>
    <p>(5) Use TraveleSIM account or this service to engage in any illegal or criminal activity;</p>
    <p>(6) Making and publishing methods and tools related to the above behaviors, or operating or disseminating such methods and tools, regardless of whether these behaviors have commercial purposes;</p>
    <p>(7) Other acts that violate laws and regulations, infringe on the lawful rights and interests of other users, interfere with the normal operation of TraveleSIM, or without express authorization by TraveleSIM.</p>
    <p>5.3 The user shall be responsible for the authenticity, legality, harmlessness, accuracy and effectiveness of the information transmitted by the TraveleSIM account or this service. Any legal responsibility related to the information disseminated by the user shall be borne by the user, has nothing to do with TraveleSIM. If any damage caused to TraveleSIM or a third party, the user shall compensate according to law.</p>
    <p>5.4 The services may include advertisements provided by TraveleSIM or third-party suppliers during users’ use. Except as clearly provided by laws and regulations, users shall be responsible for the transactions conducted on the advertisement information by themselves, and TraveleSIM shall not be liable for any loss or damage suffered by the users due to the transactions conducted on the advertisement information or the content provided by the aforementioned advertisers.</p>
    <p class="subtitle">6. account management</p>
    <p>6.1 The ownership of the TraveleSIM account belongs to TraveleSIM. After completes the application for registration, the user obtains the right to use the TraveleSIM account. The right to use only belongs to the initial application registrant, and it is prohibited to give, borrow, rent, transfer or sell . TraveleSIM has the right to recall the user's account due to business needs.</p>
    <p>6.2 The user can change or delete the personal information, registration information and transmission content on the TraveleSIM account, but it should be noted that deleting the relevant information will also delete the text and pictures stored in the system by the user. The user shall bear the risk.</p>
    <p>6.3 The user is responsible for the safety of the registered account information. The user's improper storage may lead to account theft or password theft, and the user shall bear the responsibility. Users need to bear legal responsibility for the behavior of registering an account. The user agrees not to use another user's account under any circumstances. When the user suspects that another person is using his account, the user agrees to notify TraveleSIM immediately.</p>
    <p>6.4 The user shall abide by the terms of this agreement and use the service correctly and appropriately. If the user violates any clause in this agreement, TraveleSIM has the right to suspend or terminate the provision of the user's TraveleSIM account in accordance with the agreement. At the same time, TraveleSIM reserves the right to withdraw the account at any time.</p>
    <p>6.5 If the user does not log in for one year after registering the account, TraveleSIM can withdraw the account to avoid waste of resources, and the adverse consequences shall be borne by the user.</p>
    <p class="subtitle">7. data storage</p>
    <p>7.1 TraveleSIM is not fictitious and does not upload content on its own. All data is uploaded by the user. TraveleSIM only assumes the role of depositor.</p>
    <p>7.2 TraveleSIM is not responsible for the user's failure to delete or store related data in this service.</p>
    <p>7.3 TraveleSIM can determine the maximum storage period of the user's data in this service according to the actual situation, and allocate the maximum storage space for the data on the server. Users can back up the relevant data in this service according to their own needs.</p>
    <p>7.4 If the user stops using the service or the service is terminated, TraveleSIM can permanently delete the user's data from the server. After the service is stopped or terminated, TraveleSIM has no obligation to return any data to the user.</p>
    <p class="subtitle">8. User attention</p>
    <p>8.1. If the user violates the provisions of this agreement or related terms of service; any claims, demands or losses claimed by any third party, including reasonable attorney fees, caused or generated by the user, the user agrees to compensate the company, cooperative companies, and affiliated companies.</p>
    <p>8.2. This software involves WiFi networking and Internet services, which may be affected by unstable factors in various links. Therefore, the service has the risk of service interruption or failure to meet user requirements due to the above-mentioned force majeure, virus or hacker attacks, system instability, user location, user shutdown, and any other technology, GSM network, Internet network, communication line, etc., causing service interruption or failure to meet user requirements. The user who subscribes to the service shall bear the above risks. The service party does not guarantee the timeliness, safety, and accuracy of the service, and shall not bear any responsibility for the user's communication failure caused by this.</p>
    <p>8.3 The service party does not assume any responsibility for the loss of the service due to the server crash, network interruption, and service suspension during software upgrades caused by force majeure.</p>
    <p>8.4 TraveleSIM provides automatic connection in case of network disconnection. If the user registers for use, it shall be deemed to have agreed to accept this service, and the data traffic charges incurred shall be borne by the user.</p>
    <p>8.5 Users should provide and share their own WiFi hotspot information, or ensure that other shared hotspots (including but not limited to passwords and geographic locations, etc.) are within the permission of the hotspot provider, and ensure the security of all shared WiFi hotspot information. TraveleSIM does not assume any responsibility for problems caused by violation of this article.</p>
    <p>8.6 The user should ensure that the hot information obtained by TraveleSIM is used for proper purposes, and any illegal or violation of relevant guidelines is strictly prohibited. The resulting problem has nothing to do with TraveleSIM.</p>
    <p>8.7 The hotspot provider has the right to request that the hotspot information provided by it be removed from TraveleSIM's database. TraveleSIM will deal with it accordingly in accordance with the law.</p>
    <p class="subtitle">9. Legal and dispute resolution</p>
    <p>9.1 This agreement is governed by the laws of the People's Republic of China.</p>
    <p>9.2 Any dispute arising from or related to this agreement shall be settled through friendly negotiation between the parties; if the negotiation fails, either party may submit the relevant dispute to the Shenzhen Arbitration Commission and arbitrate in accordance with its then effective arbitration rules; arbitration awards It is final and binding on all parties.</p>
    <p class="subtitle">10. Other terms</p>
    <p>10.1 If any clause in this agreement is completely or partially invalid or unenforceable for whatever reason, or violates any applicable law, the clause shall be deemed deleted, but the remaining clauses of this agreement shall still be valid and have Binding.</p>
    <p>10.2 TraveleSIM reserves the right to modify this agreement at any time in accordance with changes in relevant laws and regulations, as well as the adjustment of the company's operating conditions and business strategies. The revised agreement will be accompanied by the new version of the software release. In the event of a dispute, the latest agreement text shall prevail. If you do not agree with the changed content, the user can delete the software by himself. If the user continues to use the software, it is deemed that you accept the changes in this agreement.</p>
    <p>10.3 TraveleSIM has the right to interpret and modify this agreement to the maximum extent permitted by law.</p>`
  }
}
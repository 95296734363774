<template>
  <div class="home">
    <a-spin :spinning="isLoading" size="large">
      <swiper class="swiper" ref="bannerSwiper" :options="swiperOption" v-cloak>
        <swiper-slide
          class="swiper-slide"
          v-for="(item, index) in bannerList"
          :key="index"
        >
          <div
            class="banner-img"
            @mouseover="handleBannerStop"
            @mouseleave="handleBannerStart"
          >
            <img
              :src="item.cover"
              :data-path="item.path"
              :data-type="item.jump_type"
              :data-extra="JSON.stringify(item.extra)"
              alt=""
            />
          </div>
        </swiper-slide>
        <div
          class="swiper-pagination swiper-pagination-white"
          slot="pagination"
        ></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
      <div class="coverage-area">
        <div class="container" :title="$t('route.search')">
          <div class="section-title">{{ $t("common.area") }}</div>
          <div class="area-img flex">
            <img
              :src="require(`../assets/esim_area_${$i18n.locale}.png`)"
              alt=""
            />
          </div>
          <div class="flex">
            <button class="leave-for flex" @click="onLeaveFor">
              <span>{{ $t("common.area_global") }}</span>
              <svg viewBox="0 0 13 10" height="10px" width="15px">
                <path d="M1,5 L11,5"></path>
                <polyline points="8 1 12 5 8 9"></polyline>
              </svg>
            </button>
          </div>
        </div>
      </div>
      <reviews-bar v-if="reviewsLoad" />
      <div class="section-title">
        {{ $t("common.all") }}
      </div>
      <div class="container section-products" v-cloak>
        <van-tabs
          ref="tabs"
          v-model="active"
          :animated="isAnimated"
          swipeable
          sticky
          color="#642EC7"
          @scroll="scrollCallback"
          :offset-top="navBarHeight"
        >
          <van-tab
            :title="area.continentname"
            v-for="(area, i) in list"
            :key="i"
          >
            <a-row
              :gutter="[
                { xs: 14, sm: 20, xxl: 30 },
                { xs: 14, sm: 20, xxl: 30 },
              ]"
              class="products"
            >
              <a-col
                :xs="12"
                :sm="12"
                :md="8"
                :lg="6"
                :xl="6"
                v-for="item in area.distictSub"
                :key="item.district_id"
              >
                <div
                  class="product-item"
                  @click="handleBuyNow(item)"
                  :title="item.district_name"
                >
                  <div class="product-cover">
                    <img
                      class="product-img"
                      :src="item.cover"
                      :alt="item.district_name"
                      :title="item.district_name"
                    />
                    <img
                      class="discount-icon"
                      :style="{ opacity: item.is_sale ? 1 : 0 }"
                      src="../assets/discount_icon.png"
                      alt=""
                    />
                  </div>
                  <div class="product-info">
                    <div class="product-name text-overflow-example">
                      {{ item.district_name }}
                    </div>
                    <div class="product-lowest-price">
                      <span class="product-price-from">{{
                        $t("common.from")
                      }}</span>
                      <span>{{ currency }} {{ item.lowest_price }}</span>
                    </div>
                    <div class="product-icon flex">
                      <a-icon type="right" />
                    </div>
                  </div>
                </div>
              </a-col>
            </a-row>
          </van-tab>
        </van-tabs>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { debounce } from "../utils/dbucTrtl";
import { GetHomeDataAPI } from "../api/api";
import ReviewsBar from "../components/ReviewsBar.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    Swiper,
    SwiperSlide,
    ReviewsBar,
  },
  name: "Home",
  data() {
    return {
      active: 0,
      isFixed: false,
      isAnimated: true, //兼容Safari开发版
      isLoading: false,
      reviewsLoad: false, //页面后退重载Swiper
      bannerList: [],
      list: [],
    };
  },
  activated() {
    this.reviewsLoad = true;
    this.handleBannerStart();
    this.debouncedResizeHandler = debounce(this.handleTabsResize, 1000);
    window.addEventListener("resize", this.debouncedResizeHandler);
  },
  deactivated() {
    this.reviewsLoad = false;
    this.handleBannerStop();
    window.removeEventListener("resize", this.debouncedResizeHandler);
  },
  watch: {
    isFixed() {
      this.debouncedResizeHandler();
    },
    isLogin() {
      this.init();
    },
  },
  computed: {
    ...mapState(["navBarHeight"]),
    ...mapState("user", ["token"]),
    ...mapGetters("user", ["isLogin"]),
    ...mapGetters("config", ["currency"]),
    swiperOption() {
      return {
        loop: true,
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false,
        //   pauseOnMouseEnter: true,
        // },
        autoplay: false,
        effect: "fade",
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          click: (e) => {
            this.handleBannerClick(e.target.dataset);
          },
        },
      };
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;
      const {
        data: { banner, continent },
      } = await GetHomeDataAPI();
      this.bannerList = banner;
      this.list = continent.filter((item) => item.distictSub.length > 0);
      this.isLoading = false;
      const system = navigator.userAgent.toLowerCase();
      if (
        system.includes("macintosh") &&
        system.includes("mac os") &&
        !system.includes("chrome")
      ) {
        this.isAnimated = false;
      }
    },
    scrollCallback({ isFixed }) {
      this.isFixed = isFixed;
    },
    async handleTabsResize() {
      console.log("resize");
      this.$refs.tabs.resize();
    },
    onLeaveFor() {
      window.open(
        `https://www.triproaming.com/mapbox/travelesim/?lang=${this.$i18n.locale}`
      );
      window._hmt.push(["_trackEvent", "button", "click", `进入官网`]);
    },
    handleBannerStop() {
      this.$refs.bannerSwiper.swiperInstance.autoplay.stop();
    },
    handleBannerStart() {
      this.$refs.bannerSwiper.swiperInstance.autoplay.start();
    },
    //jump_type 公共模块：1,外部链接：2,无效果:3
    handleBannerClick(item) {
      let { type, path, extra } = item;
      type == 1 && this.$router.push({ path });
      if (type == 2) {
        const token = this.token || "";
        if (path.indexOf("?") > -1) {
          path = path + "&lang=" + this.$i18n.locale;
        } else {
          path = path + "?lang=" + this.$i18n.locale;
        }
        if (token) path = path + "&token=" + encodeURIComponent(token);
        const { target } = JSON.parse(extra);
        const open_type = target === "_blank" ? "_blank" : "_self";
        window.open(path, open_type);
        window._hmt.push([
          "_trackEvent",
          "button",
          "click",
          `Banner跳转-${path}`,
        ]);
      }
    },
    handleBuyNow({ id, name, district_id, district_name }) {
      this.$router.push({ name: "Details", query: { id: district_id || id } });
      window.scrollTo(0, 0);
      window._hmt.push([
        "_trackEvent",
        "button",
        "click",
        `浏览套餐-${district_name || name}`,
      ]);
    },
  },
};
</script>
<style lang="scss" scoped>
.swiper {
  .swiper-slide {
    .banner-img {
      img {
        cursor: pointer;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translateY(1px);
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    padding: 0 40px;
    top: 0;
    height: 100%;
    color: #fff;
    margin-top: 0;
    transition: all 0.5s ease;
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
    &::after {
      font-size: 20px;
      font-weight: 600;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }
}
.coverage-area {
  padding-top: 20px;
  padding-bottom: 80px;
  .area-img {
    margin-bottom: 20px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .leave-for {
    user-select: none;
    padding: 10px 18px;
    border: none;
    font-size: 16px;
    color: #fff;
    letter-spacing: 0.05em;
    border-radius: 24px;
    transition: all 0.2s ease;
    background: linear-gradient(to bottom right, #c53ac7 2%, #773de1 98%);
    svg {
      margin-left: 10px;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: #fff;
      stroke-width: 2;
      transform: translateX(-5px);
      transition: all 0.3s ease;
    }
    &:hover {
      svg {
        transform: translateX(0);
      }
    }
    &:active {
      transform: scale(0.95);
    }
  }
}
@media (max-width: 768px) {
  .swiper {
    .swiper-slide {
      .banner-img {
        width: 100%;
        height: 22vh;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      padding: 0 20px;
      &::after {
        font-size: 16px;
      }
    }
  }
  .coverage-area {
    padding-bottom: 20px;
    .leave-for {
      padding: 6px 16px;
      font-size: 14px;
    }
  }
}
</style>
